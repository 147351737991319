import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import MainButton from '../src/components/MainButton.vue'
import ThePageTitle from '../src/components/ThePageTitle.vue'

import Amplify from 'aws-amplify';
import aws_exports from './aws-exports';

Amplify.configure(aws_exports)

Vue.component('MainButton', MainButton)
Vue.component('ThePageTitle', ThePageTitle)

Vue.config.productionTip = false

Vue.filter('formatAmount', function(value) {
  if (value) {
    return new Intl.NumberFormat("en-GB", {
      style: "currency",
      currency: "GBP",
    }).format(value)
  }
})

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
