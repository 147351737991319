<template>
  <v-app class="">
    <v-main class="pb-0">
      <v-container>
        <v-row>
          <v-col
            class="px-0 pt-0 pt-sm-1"
            cols="12"
            sm="8"
            offset-sm="2"
            md="6"
            offset-md="3"
            xl="4"
            offset-xl="4"
          >
            <transition name="slide-down" mode="out-in">
              <ThePageTitle
                v-if="
                  $store.state.paymentDetails &&
                  $route.path !== '/' &&
                  $route.name !== 'StandingOrderSuccess' &&
                  $route.name !== 'PaymentModulrDone' &&
                  $route.name !== 'PaymentSuccess' &&
                  $route.name !== 'PaymentCancelled' &&
                  $route.name !== 'PaymentDeclined' &&
                  $route.name !== 'Paymentexception' &&
                  $route.name !== 'PaymentCardSuccess' &&
                  $route.name !== 'PaymentCardError' &&
                  $route.name !== 'PaymentHomepage' &&
                  $route.name !== 'StandingOrderHomepage'
                "
              />
            </transition>
          </v-col>
        </v-row>

        <transition :name="transitionName" mode="out-in">
          <router-view :key="$route.path" />
        </transition>
      </v-container>
    </v-main>

    <v-footer id="footer" app class="transparent">
      <v-col class="text-center" cols="12">
        <div id="logo" @click="toAgentOS">
          <v-img v-if="this.$store.state.theme === 'payagent'"
            class="mt-n1"
            contain
            src="../src/assets/PayAgentBranding/AgentOS-Logo.png"
            :height="footerLogoHeight"
          >
          </v-img>
          <v-img v-else class="mt-n1"
            contain
            src="../src/assets/CalmonyPayBranding/AgentOS-Logo.png"
            :height="footerLogoHeight"
          />
        </div>
        <p class="d-none d-sm-block mt-0 text-caption grey--text">
          &copy;{{ new Date().getFullYear() }} payAgent app
        </p>
      </v-col>
    </v-footer>

    <svg
      class="bottom-waves"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1440 320"
    >
      <path
        fill="#343351"
        fill-opacity=".05"
        d="M0,96L48,106.7C96,117,192,139,288,133.3C384,128,480,96,576,80C672,64,768,64,864,101.3C960,139,1056,213,1152,213.3C1248,213,1344,139,1392,101.3L1440,64L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
      ></path>
    </svg>
  </v-app>
</template>

<script>
import { API } from "aws-amplify";
import {calmonypaytheme} from "./plugins/themes/calmonypaytheme.js"
import {payagenttheme} from "./plugins/themes/payagenttheme.js"
export default {
  name: "App",
  created() {
    API.get("paymentAppRest", "/theme").then((res) => {
      this.$store.commit("SET_APP_THEME", res.theme);
      if(res.theme === "calmonypay"){
        this.$vuetify.theme.themes['light'] = calmonypaytheme
      }
      else{
        this.$vuetify.theme.themes['light'] = payagenttheme
      }
    });

    API.get("paymentAppRest", "/redirecturl").then((res) => {
      this.$store.commit("SET_REDIRECT_URL", res.data.redirectURL);
    });

    
    // Checking if browser is SAFARI
    let chromeAgent = navigator.userAgent.indexOf("Chrome") > -1;
    let safariAgent = navigator.userAgent.indexOf("Safari") > -1;
    if (chromeAgent && safariAgent) console.log("Browser is CHROME");
    else {
      console.log("Browser is Safari");
      this.$store.commit("SET_SAFARI_BROWSER");
    }

    // Get environmental EPDQ settings
    if (!this.$store.state.epdqData) {
      if (sessionStorage.epdqData) {
        const epdqData = JSON.parse(
          sessionStorage.getItem("epdqData")
        );
      this.$store.commit("SET_EPDQ_DETAILS", epdqData);
      }
      else{
        API.get("paymentAppRest", "/epdq").then((res) => {
          this.$store.commit("SET_EPDQ_DETAILS", res.data)
        })
      }
    }

    this.$store.state
    // Maintinaing state (for page refreshes)
    if (!this.$store.state.paymentDetails) {
      // NO payment details ======================================================
      if (sessionStorage.paymentDetails) {
        const savedPaymentDetails = JSON.parse(
          sessionStorage.getItem("paymentDetails")
        );
        this.$store.commit("SET_PAYMENT_DETAILS", savedPaymentDetails);
      }
      if (sessionStorage.paymentDetailsToSubmit) {
        const savedPaymentDetailsToSubmit = JSON.parse(
          sessionStorage.getItem("paymentDetailsToSubmit")
        );
        this.$store.commit(
          "SET_PAYMENT_DETAILS_TO_SUBMIT",
          savedPaymentDetailsToSubmit
        );
      }
      if (sessionStorage.modulrBankList) {
        const savedBankList = JSON.parse(
          sessionStorage.getItem("modulrBankList")
        );
        this.$store.commit("SET_BANK_LIST", savedBankList);
      }
      if (sessionStorage.selectedBank) {
        const savedSelectedBank = JSON.parse(
          sessionStorage.getItem("selectedBank")
        );
        this.$store.commit("SET_SELECTED_BANK", savedSelectedBank);
      }
      if (sessionStorage.modulrID) {
        const savedModulrID = JSON.parse(sessionStorage.getItem("modulrID"));
        this.$store.commit("SET_MODULR_ID", savedModulrID);
      }
      if (sessionStorage.epdqCreditDebit) {
        const savedEpdqCreditDebit = JSON.parse(
          sessionStorage.getItem("epdqCreditDebit")
        );
        this.$store.commit("SET_CREDIT_DEBIT", savedEpdqCreditDebit);
      }
    }

    if (!this.$store.state.requestID) {
      // Check session storage first
      if (sessionStorage.payAgentRequestID) {
        const savedRequestID = JSON.parse(
          sessionStorage.getItem("payAgentRequestID")
        );
        this.$store.commit("SET_REQUEST_ID", savedRequestID);
        if (sessionStorage.isDirectCardPayment) {
          this.$store.commit("SET_DIRECT_CARD_PAYMENT");
          // this.$router.push({ name: "PaymentCardConfirm" });
        }
      } else {
        // App might need resetting
        this.resetApp();
      }
    }
    if(this.$store.state.CardPaymentPermissions === ''){
      if(sessionStorage.cardPermissions)
        this.$store.commit("SET_CARD_PERMISSIONS", JSON.parse(sessionStorage.getItem("cardPermissions")))
    }
    if(this.$store.state.cardOnly === ''){
      if(sessionStorage.cardOnly){
        console.log(sessionStorage.cardOnly)
        this.$store.commit("SET_CARD_ONLY_PAYMENT", JSON.parse(sessionStorage.getItem("cardOnly")))
      }
    }
    if (!this.$store.state.genericRequestID) {
      if (sessionStorage.payAgentGenericRequestID) {
        const payAgentGenericRequestID = JSON.parse(
          sessionStorage.getItem("payAgentGenericRequestID")
        );
        this.$store.commit("SET_GENERIC_REQUEST_ID", payAgentGenericRequestID);
      }
    }
  },
  data() {
    return {
      transitionName: "scale",
      links: ["AgentOS", "payAgent", "Contact Us"],
    };
  },
  methods: {
    resetApp() {
      // Check if "requestid" present in url - if not it means app needs resetting
      const urlString = window.location.href;
      const regex1 = /requestid/;
      const regex2 = /(\/payment\/|\/standing-order\/)([a-z0-9]*)/gm;
      let paymentRef;
      let m;
      while ((m = regex2.exec(urlString)) !== null) {
        // This is necessary to avoid infinite loops with zero-width matches
        if (m.index === regex2.lastIndex) {
          regex2.lastIndex++;
        }
        paymentRef = m[2];
      }

      let urlQueryPresent = regex1.test(urlString);

      if (!urlQueryPresent && paymentRef) {
        console.log("Resetting app");
        this.$store.commit("CLEAR_STORE");
        this.$router.push({ path: `/?requestid=${paymentRef}` });
      }
    },
    toAgentOS() {
      if(this.$store.state.theme === "payagent"){
        let url = "https://agentos.com/";
        window.open(url, "_blank");
      }
      else{
        let url = "https://calmony.co/";
        window.open(url, "_blank");
      }
    },
  },
  computed: {
    footerLogoHeight() {
      if (this.$vuetify.breakpoint.xs) return "35";
      if (this.$vuetify.breakpoint.sm) return "75";
      return 100;
    },
  },
  watch: {
    // Sets the correct page transition
    $route(to, from) {
      const toDepth = to.path.split("/").length;
      const fromDepth = from.path.split("/").length;
      if (to.path === "/" && from.name === null) this.transitionName = "scale";
      else if (to.name === "PaymentHomepage" && from.path === "/")
        this.transitionName = "fade";
      else if (from.name === "BankNotShown")
        this.transitionName = "slide-right";
      else if (
        from.name === "PaymentSuccess" ||
        (from.name === "StandingOrderSuccess" && to.path === "/")
      )
        this.transitionName = "scale";
      else {
        this.transitionName =
          toDepth < fromDepth ? "slide-right" : "slide-left";
      }
    },
  },
};
</script>

<style>
#footer {
  position: absolute;
  bottom: 0;
  width: 100%;
}

#logo {
  cursor: pointer;
}

.view-transition-enter-active {
  transition: all 0.5s cubic-bezier(0.35, 1.5, 0.65, 1.1);
}

.view-transition-leave-active {
  transition: all 0.3s ease-in-out;
}

.view-transition-enter {
  opacity: 0;
  transform: translateY(30px);
}

.view-transition-leave-to {
  opacity: 0;
  transform: translateY(-30px);
}

/* ------------------------------------------- */

/* Slide left */
.slide-left-enter-active,
.slide-left-leave-active {
  transition: all 0.4s ease-in-out;
}

.slide-left-enter {
  opacity: 0;
  transform: translateX(200px);
}

.slide-left-leave-to {
  opacity: 0;
  transform: translateX(-200px);
}

/* Slide right */
.slide-right-enter-active,
.slide-right-leave-active {
  transition: all 0.4s ease-in-out;
}

.slide-right-enter {
  opacity: 0;
  transform: translateX(-200px);
}

.slide-right-leave-to {
  opacity: 0;
  transform: translateX(200px);
}

/* ------------------------------------------- */

.slide-in-out-enter-active,
.slide-in-out-leave-active {
  transition: all 0.5s cubic-bezier(0.35, 1.5, 0.65, 1.3);
}

.slide-in-out-enter,
.slide-in-out-leave-to {
  opacity: 0;
  transform: translateX(30px);
}

/* ------------------------------------------- */

.slide-down-enter-active {
  transition: all 1s ease;
}

.slide-down-leave-active {
  transition: all 0.3s ease;
}

.slide-down-enter,
.slide-down-leave-to {
  opacity: 0;
  transform: translateY(-100px);
}

/* --------------------------------------------- */

.flip-vertically-enter-active {
  transition: all 0.5s ease;
}

.flip-vertically-leave-active {
  transition: all 0.05s ease;
}

.flip-vertically-enter {
  transform: rotateX(360deg);
}

.flip-vertically-leave-to {
  transform: rotateX(0);
}

/* --------------------------------------------- */
.scale-enter-active,
.scale-leave-active {
  transition: transform 0.5s ease;
}

.scale-enter,
.scale-leave-to {
  transform-origin: center;
  transform: scale(0, 0);
}

/* --------------------------------------------- */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
