<template>
  <div
    class="py-3 mb-0 d-flex align-center"
    :class="hide ? 'justify-center' : 'justify-space-between'"
  >
    <v-btn
      v-if="!hide"
      @click="routeBack"
      class="text-body-2 font-weight-thin"
      color="primary"
      x-small
      text
      aria-label="Back to previous page"
    >
      <!-- Back -->
      <v-icon>mdi-arrow-left</v-icon>
    </v-btn>

    <div class="text-center my-0 text-body-1 font-weight-light primary--text">
      <div class="text-center">
        <span
          v-if="$store.state.paymentDetails"
          class="text-h6 my-sm-6 grey--text text--lighten-2 font-weight-black"
          >{{ $store.state.paymentDetails.Company }}</span
        >
        <span
          v-if="$store.state.openUrlCompanyName"
          class="text-h6 my-sm-6 grey--text text--lighten-2 font-weight-black"
          >{{ $store.state.openUrlCompanyName }}</span
        >
      </div>

      <span v-if="$route.name === 'BankNotShown'">Bank not shown</span>

      <div v-if="$store.state.paymentDetails">
        <span v-if="this.$store.state.cardOnly">Virtual Card Machine</span>
        <span v-else-if="$store.state.paymentDetails.Request.Frequency === 'OneOff'"
          >Make a payment</span
        >
        <span v-else>Standing order</span>
      </div>

      <div v-else>
        <span v-if="this.$store.state.cardOnly">Virtual Card Machine</span>
        <span v-else-if="$store.state.openUrlOneOffPayment">Payment request</span>
        <span v-else>Standing order</span>
      </div>
    </div>

    <p v-if="!hide" class="spacer-p"></p>
  </div>
</template>

<script>
export default {
  name: "thePageTitle",
  props: {
    hideBackButton: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    routeBack() {
      this.$router.go(-1);
    },
  },
  computed: {
    hide() {
      if (
        this.$route.name === "PaymentCardConfirm" &&
        this.$store.state.isDirectCardPayment
      )
        return true;
      else {
        return this.hideBackButton;
      }
    },
  },
};
</script>

<style scoped>
.spacer-p {
  width: 41.78px;
}
</style>
