 <template>
  <div class="text-center">
    <v-btn
      @click="reroute"
      class="mb-3 mt-5"
      x-large
      depressed
      color="primary"
      :outlined="outlined"
      block
      :disabled="disabled"
    >
      <slot></slot>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'mainButton',
  props: {
    routeTo: {
      type: String,
    },
    outlined : {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    reroute() {
      if(!this.routeTo) {
        alert('Error rerouting! Missing $route name.')
      }
      this.$router.push({ name: this.routeTo })
    }
  }
}   
</script>

<style scoped>
</style>    