import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  // BANK TRANSFER -------------------------------
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue')
  },
  // QUERY INITIATED ROUTES -----------------------------------------
  {
    // Root
    path: '/:company/payment/:reference/',
    redirect: { name: 'PaymentHomepage'},
    component: () => import('../views/PaymentRoot.vue'),
    children: [
      {
        path: 'welcome',
        name: 'PaymentHomepage',
        component: () => import('../views/PaymentHomepage.vue')
      },
      {
        path: 'details',
        name: 'PaymentDetails',
        component: () => import('../views/PaymentDetails.vue')
      },
      {
        path: 'details/select-bank',
        name: 'PaymentSelectBank',
        component: () => import('../views/PaymentSelectBank.vue')
      },
      {
        path: 'details/select-bank/confirm',
        name: 'PaymentConfirm',
        component: () => import('../views/PaymentConfirm.vue')
      },
      {
        path: 'details/card-payment/confirm',
        name: 'PaymentCardConfirm',
        component: () => import('../views/PaymentCardConfirm.vue')
      },
      // STATIC PAGES =================================================
      // USED FOR REROUTES FROM MODULR AND ePDQ
      {
        path: '/done',
        name: 'PaymentModulrDone',
        component: () => import('../views/static/PaymentModulrDone.vue')
      },
      {
        path: '/success',
        name: 'PaymentSuccess',
        component: () => import('../views/static/PaymentSuccess.vue')
      },
      {
        path: '/cancel',
        name: 'PaymentCancelled',
        component: () => import('../views/static/PaymentCancelled.vue')
      },
      {
        path: '/decline',
        name: 'PaymentDeclined',
        component: () => import('../views/static/PaymentDeclined.vue')
      },
      {
        path: '/exception',
        name: 'Paymentexception',
        component: () => import('../views/static/PaymentException.vue')
      },
    ]
  },
  // STANDING ORDERS ----------------------------
  {
    // Root
    path: '/:company/standing-order/:reference',
    redirect: { name: 'StandingOrderHomepage'},
    component: () => import('../views/StandingOrderRoot.vue'),
    children: [
      {
        path: 'welcome',
        name: 'StandingOrderHomepage',
        component: () => import('../views/StandingOrderHomepage.vue')
      },
      {
        path: 'details',
        name: 'StandingOrderDetails',
        component: () => import('../views/StandingOrderDetails.vue')
      },
      {
        path: 'details/select-bank',
        name: 'StandingOrderSelectBank',
        component: () => import('../views/StandingOrderSelectBank.vue')
      },
      {
        path: 'details/select-bank/confirm',
        name: 'StandingOrderConfirmation',
        component: () => import('../views/StandingOrderConfirm.vue')
      },
      {
        path: 'details/select-bank/confirmation/success',
        name: 'StandingOrderSuccess',
        component: () => import('../views/StandingOrderSuccess.vue')
      },
    ]
  },
  {
    path: '/404',
    name: '404',
    component: () => import('../views/static/404.vue')
  },
  {
    path: '*',
    redirect: { name: '404' }
  },
  {
    path: '/bank-not-shown',
    name: 'BankNotShown',
    component: () => import('../views/static/BankNotShown.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) { // scroll to top of page on all navigations
    if (savedPosition) {
      return savedPosition
    } else {
      return { y: 0 }
    }
  }
})

export default router
