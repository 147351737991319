var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{},[_c('v-main',{staticClass:"pb-0"},[_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"px-0 pt-0 pt-sm-1",attrs:{"cols":"12","sm":"8","offset-sm":"2","md":"6","offset-md":"3","xl":"4","offset-xl":"4"}},[_c('transition',{attrs:{"name":"slide-down","mode":"out-in"}},[(
                _vm.$store.state.paymentDetails &&
                _vm.$route.path !== '/' &&
                _vm.$route.name !== 'StandingOrderSuccess' &&
                _vm.$route.name !== 'PaymentModulrDone' &&
                _vm.$route.name !== 'PaymentSuccess' &&
                _vm.$route.name !== 'PaymentCancelled' &&
                _vm.$route.name !== 'PaymentDeclined' &&
                _vm.$route.name !== 'Paymentexception' &&
                _vm.$route.name !== 'PaymentCardSuccess' &&
                _vm.$route.name !== 'PaymentCardError' &&
                _vm.$route.name !== 'PaymentHomepage' &&
                _vm.$route.name !== 'StandingOrderHomepage'
              )?_c('ThePageTitle'):_vm._e()],1)],1)],1),_c('transition',{attrs:{"name":_vm.transitionName,"mode":"out-in"}},[_c('router-view',{key:_vm.$route.path})],1)],1)],1),_c('v-footer',{staticClass:"transparent",attrs:{"id":"footer","app":""}},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('div',{attrs:{"id":"logo"},on:{"click":_vm.toAgentOS}},[(this.$store.state.theme === 'payagent')?_c('v-img',{staticClass:"mt-n1",attrs:{"contain":"","src":require("../src/assets/PayAgentBranding/AgentOS-Logo.png"),"height":_vm.footerLogoHeight}}):_c('v-img',{staticClass:"mt-n1",attrs:{"contain":"","src":require("../src/assets/CalmonyPayBranding/AgentOS-Logo.png"),"height":_vm.footerLogoHeight}})],1),_c('p',{staticClass:"d-none d-sm-block mt-0 text-caption grey--text"},[_vm._v(" ©"+_vm._s(new Date().getFullYear())+" payAgent app ")])])],1),_c('svg',{staticClass:"bottom-waves",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 1440 320"}},[_c('path',{attrs:{"fill":"#343351","fill-opacity":".05","d":"M0,96L48,106.7C96,117,192,139,288,133.3C384,128,480,96,576,80C672,64,768,64,864,101.3C960,139,1056,213,1152,213.3C1248,213,1344,139,1392,101.3L1440,64L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"}})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }