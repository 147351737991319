import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // PAYMENTS
    oneOffPaymentMethod: 'bankTransfer',
    paymentDetails: '',
    paymentDetailsToSubmit: '',
    requestID: '',
    genericRequestID: '',
    modulrID: '',
    bankList: '',
    selectedBank: '',
    epdqCreditDebit: '',
    safariBrowser: false,
    isDirectCardPayment: false,
    epdqData : null,
    theme: 'payagent',
    redirectUrl: '',
    cardPaymentPermissions: '',
    cardOnly: ''
  },
  mutations: {
    SET_APP_THEME(state, theme){
      state.theme = theme;
    },
    SET_CARD_ONLY_PAYMENT(state, data){
      if(data === 'true')
        state.cardOnly = true;
      else
        state.cardOnly = false;
      sessionStorage.removeItem('cardOnly');
      sessionStorage.setItem('cardOnly', JSON.stringify(data))
    },
    SET_REDIRECT_URL(state, url){
      state.redirectUrl = url;
    },
    SET_DIRECT_CARD_PAYMENT(state) {
      state.isDirectCardPayment = true
      sessionStorage.setItem('isDirectCardPayment', JSON.stringify(true))
    },
    SET_SAFARI_BROWSER(state) {
      state.safariBrowser = true
    },
    SET_MODULR_ID(state, data) {
      state.modulrID = data
      sessionStorage.setItem('modulrID', JSON.stringify(data))
    },
    SET_AGENT_MARKETING_NAME(state, data) {
      state.agentMarketingName = data
    },
    SET_REQUEST_ID(state, data) {
      state.requestID = data
      sessionStorage.setItem('payAgentRequestID', JSON.stringify(data))
    },
    SET_GENERIC_REQUEST_ID(state, data) {
      state.genericRequestID = data
      sessionStorage.setItem('payAgentGenericRequestID', JSON.stringify(data))
    },
    SET_ONE_OFF_PAYMENT_METHOD(state, data) {
      state.oneOffPaymentMethod = data
    },
    SET_PAYMENT_DETAILS(state, data) {
      state.paymentDetails = data
      sessionStorage.setItem('paymentDetails', JSON.stringify(data))
    },
    SET_PAYMENT_DETAILS_TO_SUBMIT(state, data) {
      state.paymentDetailsToSubmit = data
      sessionStorage.setItem('paymentDetailsToSubmit', JSON.stringify(data))
    },
    SET_BANK_LIST(state, data) {
      state.bankList = data
      sessionStorage.setItem('modulrBankList', JSON.stringify(data))
    },
    TEST_ADD_BANKS(state, data) {
      state.bankList.push(data)
    },
    SET_SELECTED_BANK(state, data) {
      state.selectedBank = data
      sessionStorage.setItem('selectedBank', JSON.stringify(data))
    },
    SET_CREDIT_DEBIT(state, data) {
      state.epdqCreditDebit = data
      sessionStorage.setItem('epdqCreditDebit', JSON.stringify(data))
    },
    CLEAR_STORE(state) { // when to call this???
      state.paymentDetails = ''
      state.paymentDetailsToSubmit = ''
      sessionStorage.removeItem('paymentDetails')
      sessionStorage.removeItem('paymentDetailsToSubmit')
      sessionStorage.removeItem('epdqData')
      sessionStorage.removeItem('cardPermissions')
    },
    SET_EPDQ_DETAILS(state, data){
      state.epdqData = data;
      sessionStorage.setItem('epdqData', JSON.stringify(data))
    },
    SET_CARD_PERMISSIONS(state, data){
      state.cardPaymentPermissions = data;
      sessionStorage.setItem('cardPermissions', JSON.stringify(data))
    }
  },
  actions: {

  },
  getters: {
    capitalizeStaffName(state) {
      if(state.paymentDetails) {
        const staffName = state.paymentDetails.Staff
        const splitArray = staffName.split(" ")
        const capitalizedStrings = splitArray.map(string => {
          return string.charAt(0).toUpperCase() + string.slice(1);
        });
        return capitalizedStrings.join(" ")
      }
      return ''
    },
    getPSPID(state){
      return state.epdqData.pspID;
    },
    getFormUrl(state){
      return state.epdqData.formURL;
    },
    getTheme(state){
      return state.theme;
    },
    getRedirectUrl(state){
      return state.redirectUrl;
    }
  },
  modules: {
  }
})
